<template>
<div id="detail-view" body-scroll-lock-ignore>
  <div class="images">
    <img v-for="image in currentProject.images" 
      v-bind:key="image" v-bind:src="image" alt="">
     <!-- <div class="slider">
      <vue-glide  perView='1.05' wrapperheight="20">
      <vue-glide-slide class="slide"
      v-for="image in currentProject.images" 
      v-bind:key="image">
      <img v-bind:src="image" alt="">
      </vue-glide-slide>
      <template slot="control">
        <button data-glide-dir="<">prev</button>
        <button data-glide-dir=">">next</button>
      </template>
    </vue-glide>
    </div> -->
  </div>
<div class="info">
  <div class="about">
   <div v-if="currentProject">
    <h1>{{ currentProject.title }}</h1>
    <h4>{{ currentProject.subtitle }}</h4>
    <p>{{ currentProject.year }}</p>
     
    <h3>Description</h3>
    <p v-html="currentProject.shortDescription"></p>
    <h3>Partner</h3>
    <span v-for="partner in currentProject.partner" v-bind:key="partner">{{ partner }}, </span>
   </div>
  </div>
</div>
  </div>

</template>

<script>
import store from "@/store.js";

export default {
  name: "projectDetailView",
  components:{

    },
  props: {},
  data() {
    return {
      currentProject: undefined,
    };
  },
  created() {


    const foundProject = store.content.experiments.find((projObj) => {
      return projObj.title.toLowerCase().replace(/ /g,"_") === this.$route.params.projectName;
    });



    this.currentProject = foundProject;
  },
};
</script>
<style scoped lang="scss">

#detail-view{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "image image" "info info";
  //flex-direction: column;
  grid-area: content;
  overflow-y: scroll;
  // overflow-x: hidden;
  width: 100%;
  height: 100%;
  //align-items: stretch;
  //align-content: stretch;

  @media screen 
  and (min-device-width: 1000px){ 
    grid-template-areas: "image info" "image info";
}

}
img{
width: 100%;
 object-fit: cover;
}
.scroll-button{
  position: sticky;
  bottom: $padding;
  right: 0px;
}
.slider{
  border: 1px solid red;
}
.images{
  grid-area: image;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: scroll;
  border-right: $border;
  border-bottom: $border;
  flex: 1;
      @media screen 
  and (min-device-width: 1000px){ 
    flex-direction: column;
}
}

.info{
  grid-area: info;
   border-right: $border;
   border-bottom:  $border;  
   flex: 1; 
   padding: 1.5rem;
   overflow-y: scroll;
   @media screen 
  and (min-device-width: 1000px){ 
    padding-right: 100px;
}
}
.scroll-button{
  align-self: flex-end;
  transform: rotate(90deg);
  bottom: 0px;
}
</style>